.app {

  margin: 0;

  main {
    margin: 25px;
    color: #d0d5d3
  }


}