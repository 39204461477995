footer {
  height: 80px;
  background-color: #81baff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:  0 ;

  p {
    margin: 0;
  }
}